import service from '@/services/system/SystemService'

export const systemModule = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    system: null,
    systemList: [],
    isPy: false,
    moeda: process.env.VUE_APP_MOEDAPRINCIPAL ?? 0,
  },
  mutations: {
    SET_SYSTEM(state, payload) {
      if (payload.PAIS_REGIAO == 'es') state.isPy = true
      state.system = payload
    },
    SET_SYSTEM_LIST(state, payload) {
      state.systemList = payload
    },
    SET_SYSTEM_ECOMMERCE_TRUE(state) {
      state.system.HAS_MCOMMERCE = true
    },
  },
  actions: {
    async getSystem({ commit }, filial) {
      const res = await service.getSystem(filial)
      await commit('SET_SYSTEM', res)
    },
    setSystemEcommerceTrue({ commit }, payload) {
      commit('SET_SYSTEM_ECOMMERCE_TRUE')
    },
    async getSystems({ commit }, query) {
      let response = await service.getSystems(query)

      commit('SET_SYSTEM_LIST', response)
    },
    async clearSystem({ commit }) {
      await commit('SET_SYSTEM', null)
    },
  },
  getters: {
    isPy: (state) => {
      return state.isPy
    },
    getSystems: (state) => {
      return state.systemList.map((system) => ({
        DESCRICAO: system.CODIGO + ' - ' + system.NOME,
        FILIAL: system.CODIGO,
      }))
    },
    getMainCurrency: (state) => {
      let viewContext =
        localStorage.getItem('viewContext') ||
        process.env.VUE_APP_INIT_VIEWCONTEXT ||
        'companyList'

      let moeda = state.moeda ?? '0'
      if (viewContext == 'companyList') moeda = '0'

      return moeda
    },
    getSystem: (state) => {
      return state.system
    },
    systemAddress: (state) => {
      const address = state.system
        ? state.system.ENDERECO +
          ' ' +
          state.system.NUMERO +
          ', ' +
          state.system.BAIRRO +
          ' ' +
          state.system.CIDADE
        : ''
      return address
    },
    getSystemCurrencies: (state) => {
      // if (!store.state.authModule.authUser) return
      let currencies = []
      if (state.system) {
        for (let i = 0; i < 6; i++) {
          if (state.system[`MOEDA${i}`]) {
            let currencyObject = {
              descricao: state.system[`MOEDA${i}`] || `Moeda ${i}`,
              mascara: state.system[`MASCARA${i}`] || '',
              cifrao: state.system[`CIFRAO${i}`] || '',
              sinal: state.system[`SINAL${i}`] || '',
              principal: state.system['MOEDAPRINCIPAL'] == i,
              show: state.system[`CIFRAO${i}`] ? true : false,
              index: i,
            }

            currencies[i] = currencyObject
          }
        }
        return currencies
      }
    },
  },
}
