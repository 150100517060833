import service from '@/services/address/AddressService'

const frm = {
  seq: null,
  empresa: null,
  endereco: {
    label: 'Endereço',
    value: '',
  },
  numero: {
    label: 'Número',
    value: '',
  },
  complemento: {
    label: 'Complemento',
    value: '',
  },
  bairro: {
    label: 'Bairro',
    value: '',
  },
  cidade: {
    label: 'Cidade',
    value: '',
  },
  estado: {
    label: 'UF',
    value: '',
  },
  cep: {
    label: 'CEP',
    value: '',
  },
  contato: {
    label: 'Contato',
    value: '',
  },
  foneContato: {
    label: 'Telefone Contato',
    value: null,
  },
  codigoMunicipio: {
    value: null,
  },
  isExterior: false,
  isPrincipal: false,
  tipoEndereco: 'Casa',
  tipoEntrega: null,
  pais: 'BRASIL',
  siglaPais: 'BR',
}

export const addressModule = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    defaultAddress: JSON.parse(JSON.stringify(frm)),
    addresses: [],
  },
  mutations: {
    SET_ADDRESSES(state, payload) {
      state.addresses = payload
    },
  },
  actions: {
    async getAddress(context, payload) {
      return await service.getAddress(payload)
    },
    async getAddresses({ commit }, company) {
      const res = await service.getAddresses(company)
      await commit('SET_ADDRESSES', res)
    },
    async insertAddress(context, payload) {
      return await service.insert(payload)
    },
    async updateAddress(context, payload) {
      return await service.update(payload, payload.empresa)
    },
    async deleteAddress(context, payload) {
      return await service.deleteAddress(payload)
    },

    // get address from company
    async verifyAddress({ state, dispatch }, payload) {
      if (payload) {
        let newObj = JSON.parse(JSON.stringify(state.defaultAddress))

        var addressValues = null

        if (payload.seqendereco)
          addressValues = payload.empresaEnderecos.filter(
            (v) => v.seq == payload.seqendereco
          )[0]
        else if (!payload.endereco && payload.empresaEnderecos.length == 1)
          addressValues = payload.empresaEnderecos[0]
        else addressValues = JSON.parse(JSON.stringify(payload))

        Object.keys(newObj).forEach((key) => {
          if (addressValues[key]) {
            if (newObj[key] && newObj[key].hasOwnProperty('value'))
              newObj[key].value = addressValues[key]
            else newObj[key] = addressValues[key]
          }
        })

        newObj.isExterior = newObj.pais != 'BRASIL'

        if (newObj.pais == 'PARAGUAI') newObj.siglaPais = 'PY'
        else if (newObj.pais == 'ARGENTINA') newObj.siglaPais = 'AR'
        else newObj.siglaPais = 'BR'

        await dispatch('regionModule/setRegion', newObj, { root: true })
      }
    },
  },
  getters: {
    getAddresses: (state) => {
      return state.addresses
    },

    frmAddress: (state) => {
      return JSON.parse(JSON.stringify(state.defaultAddress))
    },
  },
}
