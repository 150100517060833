import service from '@/services/category-integration/CategoryIntegrationService'

export const categoryIntegrationModule = {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state: {
    categoriesIntegrationList: [],
  },
  mutations: {
    SET_CATEGORIES_INTEGRATION_LIST(state, payload) {
      state.categoriesIntegrationList = payload.data ?? payload
    },
  },
  actions: {
    async getCategoriesIntegrationAPI({ commit, rootState }, q) {
      try {
        let query = { ...q }
        query.integracao =
          rootState.adminModule.selectedIntegration?.NOME_EMPRESA
        query.TODOS = 'S'
        let response = await service.getCategoriesIntegration(query)
        commit('SET_CATEGORIES_INTEGRATION_LIST', response)
      } finally {
      }
    },
    async getCategoryIntegrationAPI({ rootState }, id) {
      try {
        let query = {}
        query.integracao =
          rootState.adminModule.selectedIntegration?.NOME_EMPRESA
        let data = await service.getCategoryIntegrationById(id, query)
        return data
      } catch(error){
        console.error(error)
      }
    },
    async insertCategoriesIntegration({ rootState }, payload) {
      const response = await service.insertCategoriesIntegration({
        data: payload,
        integracao: rootState.adminModule.selectedIntegration?.NOME_EMPRESA,
      })
      return response
    },
    async deleteCategoryIntegration({ dispatch, state }, id) {
      const status = await service.delete(id)
      dispatch('getCategoriesIntegrationAPI', {})
      return status
    },
  },
  getters: {},
}
