import Service from '@/services/Service'

class SystemService extends Service {
  constructor() {
    super('SYSTEM')
    this.resource = 'mbcommerce/system'
  }

  async getSystem(filial) {
    let requestUrl = `${this.resource}/${encodeURIComponent(filial)}`
    return await this.getAPI(requestUrl)
  }

  async getSystems(query = null) {
    if (!query) query = {}
    let requestUrl = this.createRequestUrl(query)
    return await this.getAPI(requestUrl)
  }
}

export default new SystemService()
