import service from '@/services/manufacturers/ManufacturersService'

export const manufacturersModule = {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state: {
    manufacturersList: [],
    noMoreData: false,
    last_page: null,
  },
  mutations: {
    SET_MANUFACTURERS_LIST(state, payload) {
      if (payload.next) {
        const newItem = payload.data
        const uniqueItems = newItem.filter((newItemObject) => {
          return !state.manufacturersList.some((existingItem) => {
            return existingItem.NOME === newItemObject.NOME
          })
        })
        state.manufacturersList.push(...uniqueItems)
      } else {
        state.manufacturersList = payload.data
      }
    },
    SET_INTEGRATION_FOR_ARRAY(state, payload) {
      const names = payload.map((manufacturer) => manufacturer.NOME)
      state.manufacturersList = state.manufacturersList.map((manufacturer) => {
        let obj = { ...manufacturer }
        if (names.includes(obj.NOME)) {
          obj.INTEGRADO = 'S'
        }
        return obj
      })
    },
    SET_IMAGE_FOR_MANUFACTURER(state, payload) {
      const index = state.manufacturersList.findIndex(
        (manufacturer) => manufacturer.NOME === payload.NOME
      )
      state.manufacturersList[index].IMAGEMLOAD = payload.IMAGEM
      state.manufacturersList[index].INTEGRADO = payload.INTEGRADO
      state.manufacturersList[index].IMAGEM = 'S'
    },
    SET_LAST_PAGE(state, payload) {
      state.last_page = payload
    },
  },
  actions: {
    async getManufacturers({ rootState, commit, state }, payload) {
      let query = { ...payload }
      query.integracao = rootState.adminModule.selectedIntegration?.NOME_EMPRESA
      query.admin = true
      query.hibrido = query.hibrido ?? ''
      query.page = query.page ?? 1
      const { data, last_page } = await service.getManufacturers(query)
      if (!state.last_page) {
        commit('SET_LAST_PAGE', last_page)
      }
      if (query.page > 1 || query.hibrido != '') data.next = true
      commit('SET_MANUFACTURERS_LIST', { data: data, next: data.next ?? false })
    },

    async setImageForManufacturer({ commit }, payload) {
      commit('SET_IMAGE_FOR_MANUFACTURER', payload)
    },

    setIntegrationForArray({ commit }, payload) {
      commit('SET_INTEGRATION_FOR_ARRAY', payload)
    },
  },
  getters: {
    manufacturersList: (state) => state.manufacturersList,
    last_page: (state) => state.last_page,
  },
}
