import service from '@/services/company/CompanyService'

export const companyModule = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    company: null,
    companyList: [],
    companyAddressList: [],
    loadingItems: false,
    pagination: {
      current_page: 1,
      last_page: 1,
      per_page: 15,
    },
  },
  mutations: {
    SET_COMPANYLIST(state, payload) {
      state.companyList = payload.data
      state.pagination.current_page = parseInt(payload.current_page)
      state.pagination.last_page = parseInt(payload.last_page)
      state.pagination.per_page = parseInt(payload.per_page)
    },
    SET_COMPANY(state, payload) {
      state.company = payload
    },
    SET_COMPANY_ADDRESSES(state, payload) {
      state.companyAddressList = payload.data || payload
    },
    SET_LOADING(state) {
      state.loadingItems = !state.loadingItems
    },
  },

  actions: {
    async getCompany({ commit, dispatch }, payload) {
      const response = await service.getCompany(payload)
      commit('SET_COMPANY', response)

      await dispatch('addressModule/verifyAddress', response, {
        root: true,
      })
    },
    async getCompanyCGC({ commit }, payload) {
      const response = await service.getCompanyCGC(payload)
      commit('SET_COMPANY', response)
    },
    async getCompanyAddresses({ commit }, payload) {
      const response = await service.getCompany(payload)
      commit('SET_COMPANY_ADDRESSES', response)
    },
    async insertCompany(context, payload) {
      return await service.insert(payload)
    },
    async updateCompany(context, payload) {
      return await service.update(payload)
    },
    async deleteCompany(context, payload) {
      return await service.delete(payload)
    },
  },
  getters: {
    company: (state) => {
      return state.company
    },
    companyList: (state) => {
      return state.companyList
        ? {
            id: state.CODIGO,
            name: state.NOME,
            companyName: state.RAZAOSOCIAL,
            phone: state.FONE,
            mobile: state.CELULAR,
            email: state.EMAIL,
          }
        : null
    },
    hasAddress: (state) => {
      if (parseInt(state.company.seqendereco) > 0) return true
      else if (state.company.endereco?.length > 0) return true
      else return false
    },
  },
}
