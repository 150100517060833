import service from '@/services/billing/PagoparService'

export const pagoparModule = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    currencyDefault: 'G$',
  },
  mutations: {},
  actions: {
    async getPayment(context, payload) {
      const response = await service.getPayment(payload)
      return response
    },
  },
  getters: {
    currencyDefault: (state) => {
      return state.currencyDefault
    },

    exchangeRate: (state, getters, rootState, rootGetters) => {
      const currencies = rootGetters['systemModule/getSystemCurrencies']
      const currency = currencies.find(
        (currency) => currency.cifrao == state.currencyDefault
      )

      const exchangeOrder = rootGetters['exchangeModule/exchangeOrder']
      const exchangeRate = exchangeOrder?.find(
        (exchange) => exchange.cifrao == state.currencyDefault
      )

      const exchangePagopar = {
        moeda: currency.index,
        cifrao: currency.cifrao,
        valor: exchangeRate?.valor ?? 0,
      }
      return exchangePagopar
    },
  },
}
