import http from '@/services'

export class AuthAdminService {
  constructor() {
    this._baseURL =
      (localStorage.getItem('API_URL') ||
        process.env.VUE_APP_BASEURL ||
        'http://localhost:8000/api/') + 'auth/'

    this._loginURL = this._baseURL + 'login'
    this._authUserURL = this._baseURL + 'me'
    this._logoutUrl = this._baseURL + 'logout'
  }

  async loginUser(userData) {
    const userData64 = {
      NOME: btoa(btoa(userData.NOME) + 'gw'),
      password: btoa(btoa(userData.password) + 'gw'),
    }

    const response = await http.post(this._loginURL, userData64)
    return response
  }

  async getAuthUser(token) {
    return await http.post(this._authUserURL, { token: token })
  }

  async logoutUser() {
    return await http.post(this._logoutUrl)
  }
}

export default AuthAdminService
