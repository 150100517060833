import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

const routes = [
  {
    path: '*',
    redirect: { name: 'notfound' },
  },
  {
    path: '/',
    name: 'home',
    // component: () => import('@/views/Home.vue'),
    // meta: { title: 'Home' },
    redirect: { name: 'store' },
  },
  {
    path: '/change-context',
    name: 'changeContext',
    component: () => import('../views/ChangeContext'),
    meta: { title: 'Troca de Ambiente' },
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/Home.vue'),
    meta: { title: 'Home' },
    redirect: { name: 'changeContext' },
  },
  {
    path: '/company-list',
    name: 'companyList',
    component: () => import('@/views/CompanyListAccess.vue'),
    meta: { title: 'Inicio' },
  },
  {
    path: '/company/login',
    name: 'login',
    component: () => import('@/views/company/Login'),
    meta: { title: 'Area do cliente' },
  },
  {
    path: '/company/reset',
    name: 'resetPassword',
    component: () => import('@/views/company/ResetPassword'),
    meta: { title: 'Trocar senha' },
  },
  {
    path: '/company/register',
    name: 'company',
    component: () => import('@/views/company/Register'),
    meta: { title: 'Cadastro Cliente' },
  },
  {
    path: '/company',
    name: 'dashboard',
    meta: { title: 'Dashboard Cliente' },
    children: [
      {
        path: 'orders',
        name: 'company.orders',
        component: () => import('@/views/company/Orders'),
        meta: { title: 'Pedidos' },
      },
      {
        path: '/company/update',
        name: 'company.update',
        component: () => import('@/views/company/Register'),
        meta: { title: 'Alterar Cliente' },
      },
      {
        path: '/company/address',
        name: 'company.addresses',
        component: () => import('@/views/company/Addresses'),
        meta: { title: 'Endereços Cliente' },
      },
    ],
    component: () => import('@/views/company/Index'),
  },
  {
    path: '/store',
    name: 'store',
    meta: { title: 'Loja' },
    component: () => import('@/views/Store.vue'),
  },
  {
    path: '/categories',
    name: 'categories',
    meta: { title: 'Categories' },
    component: () => import('@/views/Store.vue'),
  },
  {
    path: '/store/:product',
    name: 'store.detailproduct',
    component: () => import('@/views/store/ProductDetail'),
    meta: { title: 'Detalhes do Produto' },
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('@/views/Cart.vue'),
    meta: { title: 'Carrinho' },
  },
  {
    path: '/wish-list',
    name: 'wishList',
    component: () => import('@/views/WishList.vue'),
    meta: { title: 'Lista Desejos' },
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('@/views/Checkout.vue'),
    meta: { title: 'Checkout' },
  },
  {
    path: '/confirmation/:status/:nome',
    name: 'confirmation',
    component: () => import('@/views/Confirmation.vue'),
    meta: { title: 'Confirmar Email' },
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/ContactUs.vue'),
    meta: { title: 'Entre em contato' },
  },
  {
    path: '/thank-you',
    name: 'thankyou',
    component: () => import('@/views/ThankYou.vue'),
    meta: { title: 'Obrigado' },
  },
  {
    path: '/not-found',
    name: 'notfound',
    component: () => import('@/views/NotFound.vue'),
    meta: { title: 'Não encontrado' },
  },
  {
    path: '/agilitas',
    name: 'agilitas',
    component: () => import('@/components/checkout/AgilitasPay'),
    meta: { title: 'Não encontrado' },
  },
  {
    path: '/pagopar/:hash',
    name: 'pagopar',
    component: () => import('@/components/checkout/PagoparRedirect'),
    meta: { title: 'Não encontrado' },
  },
]

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach(async (to, from, next) => {
  try {
    if (!store.state.dialogModule.showLoadingDialog) {
      store.dispatch('dialogModule/setDialogMessageAndShow', {
        showLoading: true,
      })
    }
    const viewContext = store.state.viewContext

    const metaTitle = to.meta.title ?? ''
    const title =
      viewContext == 'companyList'
        ? metaTitle + ' - Lista Online'
        : metaTitle + ' - Ecommerce'

    document.title = title

    try {
      let system = store.state.systemModule.system
      let filial = system
        ? system.CODIGO
        : process.env.VUE_APP_SYSTEM_DEFAULT || 1

      if (
        viewContext === 'companyList' &&
        store.state.companyListModule.companyList
      )
        filial = store.state.companyListModule.companyList.FILIAL

      if (!system || system.CODIGO != filial) {
        await store.dispatch('systemModule/getSystem', filial)
        system = store.state.systemModule.system
      }
    } catch (error) {
      console.error('route getSystem error ' + error)
    }

    const idCompanyList = store.state.companyListModule.idCompanyList ?? null
    const companyList = store.state.companyListModule.companyList ?? null
    const cart = store.state.productModule.cart

    let authUser = store.state.authModule.authUser
    try {
      if (authUser) {
        await store.dispatch('authModule/checkAuthToken')
        authUser = store.state.authModule.authUser
      }
    } catch (error) {
      console.error('route checkAuthToken error ' + error)
    }

    if (store.state.networkError) {
      if (to.name == 'notfound') next()
      else next({ name: 'notfound' })
    } else if (
      viewContext == 'companyList' &&
      (to.name == 'products' || to.name == 'changeContext')
    ) {
      await store.dispatch('updateViewContext', 'products')
      if (to.name == 'changeContext') next()
      else next({ name: 'changeContext' })
    } else if (
      viewContext == 'companyList' &&
      !idCompanyList &&
      to.name !== 'companyList'
    ) {
      next({ name: 'companyList' })
    } else if (
      to.name == 'companyList' &&
      idCompanyList &&
      companyList &&
      companyList.CODIGO > 0
    ) {
      next({ name: 'home' })
    } else if (to.name == 'checkout' && cart.length <= 0) {
      next({ name: 'notfound' })
    } else if (to.name == 'checkout' && !authUser) {
      next({ name: 'login' })
    } else if (
      !authUser &&
      to.name != 'companyList' &&
      (to.name == 'dashboard' || to.name.indexOf('company.') >= 0)
    ) {
      next({ name: 'login' })
    } else if (to.name == 'login' && authUser) {
      next({ name: 'dashboard' })
    } else {
      try {
        if (viewContext !== 'products') {
          const idCompanyQuery = to.query.q ?? null
          if (idCompanyList != idCompanyQuery && idCompanyQuery) {
            await store.dispatch(
              'companyListModule/retrieveCompanyList',
              idCompanyQuery
            )
          }
        }

        if (to.name != 'categories' && to.name != 'login') {
          if (!store.state.dialogModule.showLoadingDialog)
            store.dispatch('dialogModule/setShowLoadingDialog', true)
          await store.dispatch('companyListModule/checkItemsList')
        }
      } catch (error) {
        console.error(`[route-err] Erro ao obter dados do servidor. ${error}`)
        if (error == 'ERR_NETWORK')
          console.error(
            `[ERR_NETWORK] Erro ao obter dados do servidor. ${error}`
          )
      }
      next()
    }
  } finally {
    store.dispatch('dialogModule/setShowLoadingDialog', false)
  }
})

export default router
