import service from '@/services/item/ItemService'

const itemsForIntegration = localStorage.getItem('selectedItemsForIntegration')
let listItemsForIntegration = itemsForIntegration
  ? JSON.parse(itemsForIntegration)
  : []

export const itemModule = {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state: {
    typePriceFieldname: [
      'PRECOVAREJO',
      'PRECOATACADO',
      'PRECOESPECIAL',
      'PRECOESPECIAL2',
      'PRECOESPECIAL3',
    ],
    typeCurrencyFieldname: [
      'MOEDAVAREJO',
      'MOEDATACADO',
      'MOEDAESPECIAL',
      'MOEDAESPECIAL2',
      'MOEDAESPECIAL3',
    ],
    itemList: [],
    loadingItems: false,
    pagination: {
      currentPage: 1,
      perPage: 1,
      lastPage: 1,
      total: 1,
    },
    currentSearch: '',
    listItemsForIntegration,
  },
  mutations: {
    CLEAR_ITENS(state) {
      state.itemList = []
    },
    SET_CURRENT_SEARCH(state, payload) {
      state.currentSearch = payload
    },
    SET_PAGINATION(state, payload) {
      if (payload && payload.current_page) {
        state.pagination.currentPage = payload.current_page
        state.pagination.lastPage = payload.last_page
        state.pagination.total = payload.total
      }
      state.pagination.perPage = parseInt(payload.per_page)
    },
    SET_ITENS(state, payload) {
      state.itemList = payload.data ?? []
    },
    ADD_ITENS(state, payload) {
      let dados = payload.data ?? payload
      state.itemList = state.itemList.concat(dados)
    },

    SET_LOADING_ITENS(state) {
      state.loadingItems = !state.loadingItems
    },

    SET_ITEM_FOR_LIST_ITEMS_INTEGRATION(state, payload) {
      const idx = state.listItemsForIntegration.findIndex(
        (item) => item.CODIGO == payload.CODIGO
      )
      state.listItemsForIntegration[idx] = payload
      state.listItemsForIntegration[idx].SAVED = true
      localStorage.setItem(
        'selectedItemsForIntegration',
        JSON.stringify(state.listItemsForIntegration)
      )
    },

    SET_CHANGES_IN_ALL_ITEMS_FOR_LIST_ITENS_INTEGRATION(state, payload) {
      const newItems = state.listItemsForIntegration.map((item) => {
        let obj = { ...payload }
        obj.CODIGO = item.CODIGO
        obj.DESCRICAO = item.DESCRICAO
        obj.SAVED = true
        return obj
      })
      state.listItemsForIntegration = newItems
      localStorage.setItem(
        'selectedItemsForIntegration',
        JSON.stringify(state.listItemsForIntegration)
      )
    },

    SET_LIST_ITEMS_FOR_INTEGRATION(state, payload) {
      // lista de itens para integração
      payload = payload.data ?? payload
      state.listItemsForIntegration = payload.map((item) => {
        let obj = {
          CODIGO: item.CODIGO,
          DESCRICAO: item.DESCRICAO,
        }
        const exist = state.listItemsForIntegration.find(
          (i) => i.CODIGO == obj.CODIGO
        )
        if (exist) {
          obj = exist
        }
        return obj
      })
      localStorage.setItem(
        'selectedItemsForIntegration',
        JSON.stringify(state.listItemsForIntegration)
      )
    },

    REMOVE_ITEM_FROM_LIST_ITEMS_INTEGRATION(state, id) {
      const idx = state.listItemsForIntegration.findIndex((e) => e.CODIGO == id)
      state.listItemsForIntegration.splice(idx, 1)

      localStorage.setItem(
        'selectedItemsForIntegration',
        JSON.stringify(state.listItemsForIntegration)
      )
    },

    SET_OBS_ITEM(state, payload) {
      const idx = state.itemList.findIndex(
        (item) => item.CODIGO == payload.CODIGO
      )
      state.itemList[idx].OBS = payload.OBS
    },
  },
  actions: {
    setObsItem(state, payload) {
      state.commit('SET_OBS_ITEM', payload)
    },

    async getItems({ commit }, { query, type = 'item' }) {
      try {
        commit('SET_LOADING_ITENS')

        let response = await service.getItems(query)
        commit('SET_PAGINATION', response)
        //console.log(JSON.stringify(response.data))
        if (type == 'item') commit('SET_ITENS', response)
        else commit('ADD_ITENS', response)
      } finally {
        commit('SET_LOADING_ITENS')
      }
    },

    setCurrentSearch({ commit }, payload) {
      commit('SET_CURRENT_SEARCH', payload)
    },

    async getItem({ rootState }, payload) {
      let query = {
        admin: true,
        integracao: rootState.adminModule.selectedIntegration.NOME_EMPRESA,
      }
      if (payload && payload.id) {
        query.log = payload.log
        payload = payload.id
      }

      let data = await service.getItem(payload, query)
      return data
    },

    async removeItemFromItemsIntegration({ commit }, payload) {
      commit('REMOVE_ITEM_FROM_LIST_ITEMS_INTEGRATION', payload)
    },

    getItemFromListItemIntegration({ state }, payload) {
      const item = state.listItemsForIntegration.find(
        (item) => item.CODIGO == payload
      )
      return item
    },

    setListForItemsIntegration({ commit }, payload) {
      commit('SET_LIST_ITEMS_FOR_INTEGRATION', {
        origin: 'list',
        data: payload,
      })
    },

    setChangesInAllItems({ commit }, payload) {
      try {
        commit('SET_CHANGES_IN_ALL_ITEMS_FOR_LIST_ITENS_INTEGRATION', payload)
      } catch (e) {
        console.error(e)
      }
    },

    setItemForListItemsIntegration({ commit }, payload) {
      commit('SET_ITEM_FOR_LIST_ITEMS_INTEGRATION', payload)
    },

    async getSelectedItens({ getters, state, rootState }, payload = false) {
      const query = {
        selectedItems: getters.getItemsForIntegration,
        preview: payload,
        integracao: rootState.adminModule.selectedIntegration.NOME_EMPRESA,
      }
      let { data } = await service.getSelectedItem(query) // pega os itens selecionados
      data = data.map((item) => {
        // caso houver alterações deles em cache, será utilizado essa informações, mantendo apenas algumas info originais.
        let obj = { ...item }
        if (
          state.listItemsForIntegration.find((i) => i.CODIGO == item.CODIGO)
            .SAVED != undefined
        ) {
          const saldo = obj.SALDOITEM
          const integrado = obj.INTEGRADO
          const peso = obj.PESO ? obj.PESO : null
          const medida = obj.MEDIDA ? obj.MEDIDA : null
          const pesoLiquido = obj.PESOLIQUIDO ? obj.PESOLIQUIDO : null
          obj = state.listItemsForIntegration.find(
            (i) => i.CODIGO == item.CODIGO
          )
          obj.PRECOITEM = obj.PRECO
          obj.SALDOITEM = saldo
          obj.INTEGRADO = integrado
          obj.PESO = peso
          obj.MEDIDA = medida
          obj.PESOLIQUIDO = pesoLiquido
        }
        return obj
      })
      return data
    },
  },
  getters: {
    getComboBoxList: (state) => {
      if (state.itemList.total == 0) return
      let comboBox = state.itemList.map((item) => {
        const codBarras = item.CODIGOBARRAS ?? ''
        return {
          CODIGO: item.CODIGO,
          DESCRICAO:
            item.CODIGO +
            ' - ' +
            item.DESCRICAO +
            ' | Cód. Barras: ' +
            codBarras,
          DESCRICAO1: item.DESCRICAO,
        }
      })
      return comboBox
    },

    getItemList: (state) => {
      return state.itemList
    },
    getPagination: (state) => {
      return state.pagination
    },
    getSelectedItems: (state) => {
      return state.selectedItemsList
    },
    getItemsForIntegration: (state) => {
      return state.listItemsForIntegration.map((i) => i.CODIGO)
    },

    currentSearch: (state) => {
      return state.currentSearch
    },
  },
}
