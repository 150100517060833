import Vue from 'vue'
import App from './App.vue'
import routerEcommerce from './router/ecommerce'
import routerAdmin from './router/admin'
// import router from './router'
import store from './store'
import Vuetify from 'vuetify/lib'
import vuetify from './plugins/vuetify'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import i18n from './i18n'
import '../public/reset.css'
import '../public/style.css'

Vue.config.productionTip = false
const isContextAdmin = window.location.pathname.startsWith('/admin')
const router = isContextAdmin ? routerAdmin : routerEcommerce

if (!isContextAdmin) {
  const payload = {
    filial: process.env.VUE_APP_SYSTEM_DEFAULT ?? 1,
    integration: 'MCommerce',
  }
  const cores = await store.dispatch('integrationModule/getColors', payload)
  if (cores && Object.keys(cores).length > 0) {
    const themes = JSON.parse(cores)

    themes.forEach((e) => {
      const tmp = vuetify.preset.theme.themes[e.name]
      vuetify.preset.theme.themes[e.name] = { ...tmp, ...e.theme }
    })
  }
}

store.dispatch(
  'adminModule/setAdminContext',
  isContextAdmin ? 'Admin' : 'Ecommerce'
)

// var $eventBus = new Vue();
Vue.prototype.$eventBus = new Vue()
const tmpVueTify = new Vuetify({
  theme: {
    breakpoint: vuetify.preset.breakpoint,
    icons: vuetify.preset.icons,
    lang: vuetify.preset.lang,
    themes: vuetify.preset.theme.themes,
  },
})
new Vue({
  router,
  store,
  vuetify: tmpVueTify,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
