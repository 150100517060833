import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

const routes = [
  {
    path: '*',
    redirect: { name: 'notfound' },
  },
  {
    path: '/admin/not-found',
    name: 'notfound',
    component: () => import('@/views/NotFound.vue'),
    meta: { title: 'Não encontrado' },
  },
  {
    path: '/admin/home',
    redirect: { name: 'home' },
  },
  {
    path: '/admin/admin',
    redirect: { name: 'home' },
  },
  {
    path: '/admin/',
    name: 'home',
    component: () => import('@/views/admin/home/Index'),
    meta: { title: 'Home' },
  },
  {
    path: '/admin/login',
    name: 'login',
    component: () => import('@/views/admin/login/Index'),
    meta: { title: 'Login' },
  },
  {
    path: '/admin/integracao',
    name: 'selectIntegracion',
    component: () => import('@/views/admin/integration/SelectIntegration'),
    meta: { title: 'Integração' },
  },
  {
    path: '/admin/integracao/registrar/:id',
    name: 'registerIntegracion',
    component: () => import('@/views/admin/integration/RegisterNew'),
    meta: { title: 'Registro-Integração' },
  },
  {
    path: '/admin/configuracoes',
    meta: { title: 'Configurações' },
    component: () => import('@/views/admin/settings/Settings'),
    children: [
      {
        path: 'integracoes',
        name: 'settings.integrations',
        component: () => import('@/views/admin/settings/integrations/Index'),
      },
    ],
  },
  {
    path: '/admin/integracoes/ecommerce',
    name: 'integration',
    component: () => import('@/views/admin/settings/integrations/Index.vue'),
    meta: { title: 'Produtos' },
  },
  {
    path: '/admin/manufacturers',
    name: 'manufacturers',
    component: () => import('@/views/admin/manufacturers/Index'),
    meta: { title: 'Fabricantes' },
  },
  {
    path: '/admin/produtos',
    name: 'products',
    component: () => import('@/views/admin/products/Index'),
    meta: { title: 'Produtos' },
  },
  {
    path: '/admin/produtos/selecionadas',
    name: 'products.integration.select',
    component: () => import('@/views/admin/products/SelectedProducts'),
    meta: { title: 'Produtos' },
  },
  {
    path: '/admin/produtos/integracao',
    name: 'products.integration',
    component: () => import('@/views/admin/products-integration/Index'),
    meta: { title: 'Produtos Integração' },
  },
  {
    path: '/admin/produtos/integracao/:view/alterar/:id',
    name: 'products.integration.manage',
    component: () =>
      import('@/views/admin/products-integration/RegisterProducts'),
    meta: { title: 'Produtos Integração Edição' },
  },
  {
    path: '/admin/produtos/integracao/seleciondos/alterar',
    name: 'products.integration.manage.all',
    component: () =>
      import('@/views/admin/products-integration/EditAllProducts'),
    meta: { title: 'Produtos Integração Edição' },
  },

  {
    path: '/admin/categorias',
    name: 'categories',
    component: () => import('@/views/admin/categories/Index'),
    meta: { title: 'Categorias' },
  },
  {
    path: '/admin/categorias/selecionadas',
    name: 'categories.integration.select',
    component: () => import('@/views/admin/categories/SelectedCategories'),
    meta: { title: 'Categorias' },
  },
  {
    path: '/admin/categorias/integracao',
    name: 'categories.integration',
    component: () => import('@/views/admin/categories-integration/Index'),
    meta: { title: 'Categorias Integração' },
  },
  {
    path: '/admin/clientes',
    name: 'clients',
    component: () => import('@/views/admin/clients/Index'),
    meta: { title: 'Clientes' },
  },
  {
    path: '/admin/orcamentos',
    name: 'orders',
    component: () => import('@/views/admin/orders/Index'),
    meta: { title: 'Vendas' },
  },
  {
    path: '/admin/frete',
    name: 'shipping',
    component: () => import('@/views/admin/shipping/Index'),
    meta: { title: 'Frete' },
  },
]

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach(async (to, from, next) => {
  try {
    try {
      let response = await store.dispatch('authAdminModule/checkAuthToken')

      if (response) {
        let system = store.state.systemModule.system
        let filial = 1
        let filialAdmin = store.state.adminModule.selectedFilial

        if (filialAdmin) filial = filialAdmin
        else filial = system ? system.CODIGO : filial

        if (!system || system.CODIGO != filial) {
          store.dispatch('dialogModule/setShowLoadingDialog', true)
          await store.dispatch('systemModule/getSystem', filial)
        }
      }

      const adminContext = store.state.adminModule.adminContext
      let authUser = store.state.authAdminModule.authAdminUser
      const selectedIntegration = store.state.adminModule.selectedIntegration
      const filial = store.state.adminModule.selectedFilial

      const metaTitle = to.meta.title ?? ''
      const title = 'Admin ' + ' - ' + metaTitle

      document.title = title
      let hasMCommerce = false
      if (
        store.state.systemModule.system?.hasOwnProperty('HAS_MCOMMERCE') &&
        store.state.systemModule.system.HAS_MCOMMERCE == true
      ) {
        hasMCommerce = true
      }

      if (adminContext != 'Admin' || (to.name !== 'login' && !authUser)) {
        next({ name: 'login' })
      } else if (
        authUser &&
        (!selectedIntegration || !filial) &&
        to.name != 'selectIntegracion' &&
        to.name != 'registerIntegracion'
      ) {
        const msg = !hasMCommerce
          ? 'Realize o cadastro da integração E-commerce !'
          : `Selecione uma integração.`

        if (hasMCommerce) next({ name: 'selectIntegracion' })
        else next({ name: 'registerIntegracion', params: { id: 'MCommerce' } })

        await store.dispatch('updateSnackbar', {
          show: true,
          message: msg,
          variant: 'error',
          duration: 5000,
        })
        return
      } else if (to.name == 'login' && authUser) {
        if (hasMCommerce) next({ name: 'home' })
        else next({ name: 'registerIntegracion', params: { id: 'MCommerce' } })
      } else {
        if (
          selectedIntegration?.NOME_EMPRESA != 'MCommerce' &&
          to.name != 'home' &&
          to.name != 'selectIntegracion' &&
          to.name != 'login' &&
          to.name != 'registerIntegracion' &&
          to.name != 'settings.integrations'
        ) {
          await store.dispatch('updateSnackbar', {
            show: true,
            message: `Selecione a integração MCommerce para isso!`,
            variant: 'error',
            duration: 5000,
          })

          if (hasMCommerce) next({ name: 'selectIntegracion' })
          else
            next({ name: 'registerIntegracion', params: { id: 'MCommerce' } })
        } else {
          if (
            hasMCommerce ||
            to.name == 'registerIntegracion' ||
            to.name == 'login'
          )
            next()
          else
            next({ name: 'registerIntegracion', params: { id: 'MCommerce' } })
        }
      }
    } catch (error) {
      console.error('route getSystem error ' + error)
      next(error)
    }
  } finally {
    store.dispatch('dialogModule/setShowLoadingDialog', false)
  }
})

const onError = (e) => {
  if (
    !e?.message.includes('Redirected when going from') &&
    !e?.message.includes('Avoided redundant navigation')
  ) {
    throw e
  }
}

const originalRequest = router.__proto__.push

router.__proto__.push = function push(...args) {
  try {
    const op = originalRequest.call(this, ...args)
    if (op instanceof Promise) op.catch(onError)
    return op
  } catch (e) {
    onError(e)
  }
}
export default router
