import service from '@/services/item-integration/ItemIntegrationService'

export const itemIntegrationModule = {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state: {
    itemsIntegrationList: [],
    noMoreData: false,
  },
  mutations: {
    SET_ITEMS_INTEGRATION_LIST(state, payload) {
      if (payload.forward) {
        const newItem = payload.data
        const uniqueItems = newItem.filter((newItemObject) => {
          return !state.itemsIntegrationList.some((existingItem) => {
            return existingItem.CODIGO === newItemObject.CODIGO
          })
        })
        state.itemsIntegrationList.push(...uniqueItems)
      } else {
        state.itemsIntegrationList = payload.data
      }
    },
    SET_OBS_ITEM(state, payload) {
      const idx = state.itemsIntegrationList.findIndex(
        (item) => item.CODIGO == payload.CODIGO
      )
      state.itemsIntegrationList[idx].OBS = payload.OBS
    },
    REMOVE_ITEM_FROM_LIST(state, payload) {
      const idx = state.itemsIntegrationList.findIndex(
        (item) => item.CODIGO == payload
      )
      state.itemsIntegrationList.splice(idx, 1)
    },

    REFRESH_ITEMS_AFTER_CHANGES(state, payload) {
      state.itemsIntegrationList = payload
    },
  },
  actions: {
    setObsItem({ commit }, payload) {
      try {
        commit('SET_OBS_ITEM', payload)
      } catch (error) {
        console.error(error)
      }
    },

    async getItemsIntegrationAPI({ commit, rootState, state }, q) {
      try {
        let query = { ...q }

        query.integracao =
          rootState.adminModule.selectedIntegration?.NOME_EMPRESA
        query.admin = true
        query.hibrido = query.hibrido ?? ''
        query.page = query.page ?? 1
        query.per_page = query.per_page ?? 15
        let response = await service.getItemsIntegration(query)
        if (response.total == 0) state.noMoreData = true
        if (query.page > 1 || query.hibrido) response.forward = true
        commit('SET_ITEMS_INTEGRATION_LIST', response)
      } catch (e) {
        console.log(e)
      }
    },
    async getItemIntegrationAPI({}, CODIGO) {
      let data = await service.getItemIntegration(CODIGO)
      return data
    },
    async insertItemIntegration({ rootState, dispatch }, payload = null) {
      try {
        const listPayload = !payload
          ? rootState.itemModule.listItemsForIntegration
          : payload
        const integracao =
          rootState.adminModule.selectedIntegration?.NOME_EMPRESA
        const { data } = await service.insertItemIntegration(
          { itens: listPayload },
          integracao
        )
        return data
      } catch (error) {
        console.error(error)
      }
    },
    async deleteItemIntegration({ commit, rootState }, CODIGO) {
      const query = {
        integracao: rootState.adminModule.selectedIntegration?.NOME_EMPRESA,
      }
      const status = await service.deleteItemIntegration(CODIGO, query)
      commit('REMOVE_ITEM_FROM_LIST', CODIGO)
      return status
    },
  },
  getters: {
    itemsIntegrationList: (state) => state.itemsIntegrationList,
    noMoreData: (state) => state.noMoreData,
  },
}
