import service from '@/services/integration/IntegrationService'

const defaultList = [
  {
    ATIVO: 'N',
    IMAGEM: 'mcommerce_h.svg',
    NOME_EMPRESA: 'MCommerce',
    DESCRICAO:
      'Integração base para o funcionamento do E-commerce, com ela será possivel definir os itens que serão utilizados no e-commerce, bem como adicionar novos serviços e formas de pagamento para o mesmo.',
    TIPOINTEGRACAO: 'servico',
    DEFAULTIMG: true,
  },
  {
    ATIVO: 'N',
    IMAGEM: 'frenet.png',
    NOME_EMPRESA: 'Frenet',
    DESCRICAO:
      'A integração com a Frenet conecta transportadoras à sua loja virtual e ajuda você a oferecer mais opções de entrega aos seus clientes.',
    TIPOINTEGRACAO: 'servico',
    DEFAULTIMG: true,
  },

  {
    ATIVO: 'N',

    IMAGEM: 'logo_banco_brasil.png',
    NOME_EMPRESA: 'Pix (BB)',
    DESCRICAO: 'Integração de pagamento via Pix com o Banco do Brasil.',
    TIPOINTEGRACAO: 'pagamento',
    DEFAULTIMG: true,
  },
  {
    ATIVO: 'N',
    IMAGEM: 'logo_sicredi.png',
    NOME_EMPRESA: 'Pix (SC)',
    DESCRICAO: 'Integração de pagamento via pix com o Banco Sicredi.',
    TIPOINTEGRACAO: 'pagamento',
    DEFAULTIMG: true,
  },
  {
    ATIVO: 'N',

    IMAGEM: 'rendimento_pay_favicon2.webp',
    NOME_EMPRESA: 'Rendimento Pay',
    DESCRICAO: 'Integração de pagamento via Rendimento Pay.',
    TIPOINTEGRACAO: 'pagamento',
    DEFAULTIMG: true,
  },
  {
    ATIVO: 'N',
    IMAGEM: 'pagopar.png',
    NOME_EMPRESA: 'Pagopar',
    DESCRICAO:
      'Integração de pagamento via Pagopar (exclusivo para empresas do Paraguai).',
    TIPOINTEGRACAO: 'pagamento',
    DEFAULTIMG: true,
  },
]
export const integrationModule = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    integration: {},
    coresEcommerce: null,
    loadRequest: null,
    integrationsList: defaultList,
  },
  mutations: {
    SET_INTEGRATIONS_LIST(state, payload) {
      if (payload.length < 1) {
        payload = defaultList.map((e) => {
          let obj = { ...e }
          return obj
        })
      } else {
        payload = defaultList.map((e) => {
          let obj = { ...e }
          const exist = payload.filter(
            (p) => p.NOME_EMPRESA == obj.NOME_EMPRESA
          )[0]
          if (exist) {
            if (exist.IMAGEM == null) {
              exist.IMAGEM = defaultList.filter(
                (i) => i.NOME_EMPRESA == exist.NOME_EMPRESA
              )[0].IMAGEM
              exist.DEFAULTIMG = true
            } else {
              exist.DEFAULTIMG = false
            }
            exist.DESCRICAO = obj.DESCRICAO
            obj = { ...exist }
          }
          return obj
        })
      }

      state.integrationsList = payload
    },
    SET_SELECTED_INTEGRATION(state, payload) {
      const exist = state.integrationsList.filter(
        (p) => p.NOME_EMPRESA == payload.NOME_EMPRESA
      )[0]
      if (exist) {
        if (payload.IMAGEM == null) {
          payload.IMAGEM = exist.IMAGEM
          payload.DEFAULTIMG = true
        }
      }
      state.integration = payload
    },

    SET_NEW_IMAGE_FOR_INTEGRATION(state, payload) {
      const idx = state.integrationsList.findIndex(
        (e) => e.NOME_EMPRESA == payload.NOME_EMPRESA
      )
      if (idx > -1) {
        state.integrationsList[idx].IMAGEM = payload.IMAGEM
        state.integrationsList[idx].DEFAULTIMG = false
      }
    },

    SET_INTEGRATION_ACTIVE(state, payload) {
      const idx = state.integrationsList.findIndex(
        (e) => e.NOME_EMPRESA == payload
      )
      if (idx > -1) state.integrationsList[idx].ATIVO = 'S'
    },
  },
  actions: {
    async getIntegrations({ commit }) {
      try {
        const { data } = await service.getIntegrations({ admin: true })
        commit('SET_INTEGRATIONS_LIST', data)
      } catch (error) {
        console.error(error)
      }
    },

    setNewImageForIntegration({ commit }, payload) {
      try {
        commit('SET_NEW_IMAGE_FOR_INTEGRATION', payload)
      } catch {
        console.error(error)
      }
    },
    async getIntegration({ commit, dispatch }, payload) {
      try {
        const response = await service.getIntegration(payload)
        commit('SET_SELECTED_INTEGRATION', response)
        return response
      } catch (error) {
        dispatch('adminModule/clearSelectedIntegration', null, {
          // caso a integração não exista mais, remove a seleção.
          root: true,
        })
        console.error(error)
      }
    },
    async setSelectedIntegration({ commit }, payload) {
      try {
        commit('SET_SELECTED_INTEGRATION', payload)
      } catch (error) {
        console.error(error)
      }
    },

    async verifyIfExist({ commit }, payload) {
      try {
        const { data } = await service.verifyIntegration(payload)
        if (data) {
          await commit('SET_INTEGRATION_ACTIVE', payload)
        } else {
          return data
        }
      } catch (error) {
        console.error(error)
      }
    },

    getBaseImageFromIntegration({ state }, payload) {
      try {
        const data = state.integrationsList.filter(
          (e) => e.NOME_EMPRESA == payload
        )[0]

        return data
      } catch (error) {
        console.error(error)
      }
    },

    async updateIntegration({}, payload) {
      try {
        return await service.updateIntegration(payload)
      } catch (error) {
        console.error(error)
      }
    },

    async saveIntegration({ dispatch }, payload) {
      try {
        const response = await service.saveIntegration(payload)
        if (response.data.NOME_EMPRESA == 'MCommerce') {
          dispatch('systemModule/setSystemEcommerceTrue', true, {
            // caso a integração não exista mais, remove a seleção.
            root: true,
          })
        }
        return response
      } catch (error) {
        console.error(error)
      }
    },

    async getColors({ state }, payload) {
      try {
        const query = { filial: payload.filial }
        return await service.getColors(payload, query)
      } catch (error) {
        console.error(error)
      }
    },
  },
  getters: {
    listaCbIntegrations(state) {
      if (state.integrationsList.length < 1) return
      let array = state.integrationsList.map((int) => {
        return {
          FILIAL: int.FILIAL,
          DESCRICAO: int.FILIAL + ' - ' + int.NOME_EMPRESA,
          NOME_EMPRESA: int.NOME_EMPRESA,
          VALUE: int.FILIAL + '_' + int.NOME_EMPRESA,
        }
      })
      return array
    },

    hasFrenet(state) {
      let frenet = state.integrationsList.filter(
        (e) => e.NOME_EMPRESA == 'Frenet'
      )[0].ATIVO
      return frenet === 'S'
    },

    selectedIntegration(state) {
      return state.integration
    },

    getCifraoFromSelectedIntegration(state) {
      return state.integration.CIFRAO
    },

    getMoedaFromSelectedIntegration(state) {
      return state.integration.MOEDA
    },

    getDefaultColors(state) {
      return JSON.parse(state.integration.CORES)
    },
  },
}
