export const headerModule = {
  namespaced: true,
  state: {
    itemsMenu: [
      {
        id: null,
        title: "Entre ou cadastre-se",
        link: "/company",
        route: { name: "dashboard" },
        icon: "",
        size: "36px",
      },
      {
        id: "wishListQty",
        title: "Favoritos",
        link: "/wish-list",
        route: { name: "wishList" },
        icon: "",
        size: "34px",
      },
      {
        id: "cartQty",
        title: "Carrinho",
        link: "/cart",
        route: { name: "cart" },
        icon: "",
        size: "38px",
      },
    ],
  },
  mutations: {
  },
  actions: {
  },
  getters: {
    itemsMenu: (state) => {
      return state.itemsMenu;
    }
  }
}
