import service from '@/services/order/OrderService'
import {
  verifyPropertyNameByContext,
  getObjectFromStorage,
} from '@/utils/utils'

const ORDER_PROPERTY = 'order'

let order = getObjectFromStorage(verifyPropertyNameByContext(ORDER_PROPERTY))

export const orderModule = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    order: order,
    templateOrder: {
      empresa: {
        razaosocial: null,
        cgc: null,
        email: null,
        fone: null,
        endereco: null,
        numero: null,
        bairro: null,
        cidade: null,
        estado: null,
        cep: null,
      },
      total: 0,
      items: [],
    },
    loadingItems: false,
  },
  mutations: {
    SET_ORDER(state, payload) {
      state.order = Object.assign({}, payload)

      localStorage.setItem(
        verifyPropertyNameByContext(ORDER_PROPERTY),
        JSON.stringify(payload)
      )
    },
  },
  actions: {
    setOrder({ commit }, payload) {
      commit('SET_ORDER', payload)
    },

    async getOrder(context, payload) {
      return await service.getOrder(payload)
    },

    async getLastOrder(context, payload) {
      return await service.getLastOrder(payload)
    },

    async getOrders(context, payload) {
      return await service.getOrders(payload)
    },

    async saveOrder(context, payload) {
      return await service.insert(payload)
    },

    async getItemsFromOrder({}, payload) {
      return await service.getItemsFromOrder(payload)
    },
  },
  getters: {
    order: (state) => {
      return state.order
    },
    templateOrder: (state) => {
      return state.templateOrder
    },
  },
}
