import Service from '@/services/Service'

class CompanyService extends Service {
  constructor() {
    super('COMPANY')
    this.resource = 'mbcommerce/company'
  }

  createRequestUrl(query) {
    let requestUrl = `${this.resource}?`

    if (query) query = this.encodeQueryValues(query)

    if (query.todos)
      requestUrl += this.hasParameter(requestUrl)
        ? `&TODOS=${query.todos}`
        : `TODOS=${query.todos}`
    else
      requestUrl += this.hasParameter(requestUrl)
        ? `&page=${query.page}`
        : `page=${query.page}`
    if (query.codigo)
      requestUrl += this.hasParameter(requestUrl)
        ? `&CODIGO=${query.codigo}`
        : `CODIGO=${query.codigo}`
    if (query.nome)
      requestUrl += this.hasParameter(requestUrl)
        ? `&NOME=${query.nome}`
        : `NOME=${query.nome}`

    if (query.filtro)
      requestUrl += this.hasParameter(requestUrl)
        ? `&filtro=${query.filtro}`
        : `filtro=${query.filtro}`
    return requestUrl
  }

  async getCompany(payload) {
    let requestUrl = `${this.resource}/${encodeURIComponent(payload)}`
    return await this.getAPI(requestUrl)
  }

  async getCompanyCGC(payload) {
    let requestUrl = `${this.resource}/doc/${encodeURIComponent(payload)}`
    return await this.getAPI(requestUrl)
  }

  async getCompanyAddresses(payload) {
    let requestUrl = `${this.resource}/address/${encodeURIComponent(payload)}`
    return await this.getAPI(requestUrl)
  }
}

export default new CompanyService()
